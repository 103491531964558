import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {DialogData} from './dialog-data';
import {SafeHtml} from '@angular/platform-browser';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-dialog-validation',
  templateUrl: './dialog-validation.component.html',
  styleUrls: ['./dialog-validation.component.css']
})
export class DialogValidationComponent implements OnInit {
  dialogData: DialogData = new DialogData();
  iconPrefix: SafeHtml = '';
  ready = false;
  form: UntypedFormGroup;
  contentData: string;
  icon = '';
  max = 100000;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<DialogValidationComponent>,
              private fb: UntypedFormBuilder) {
    this.contentData = data.contentData;

    if (data) {
      this.dialogData = data;
      if (this.dialogData.iconType) {
        this.icon = this.dialogData.iconType;
        this.iconPrefix = this.dialogData.iconType;
      }
    }
  }

  ngOnInit() {
    if (this.dialogData.content) {
      if (this.dialogData.contentMaxLength) {
        this.max = this.dialogData.contentMaxLength;
      }
      const validators: ValidatorFn[] = [Validators.maxLength(this.max)];
      const min = this.dialogData.contentMinLength || 0;
      if (min) {
        validators.unshift(Validators.required);
        validators.push(Validators.minLength(min));
      }
      this.form = this.fb.group({
        contentData: [this.contentData, validators],
        confirm: true,
      });
    }
    setTimeout(() => this.ready = true, 0);
  }
  isDisabled() {
    if (this.dialogData.disableButton) {
      return this.changeButtonStatus();
    }
    return false;
  }

  changeButtonStatus() {
    if (this.dialogData.content) {
      return !this.form.valid;
    }
    return false;
  }
  save() {
    if (this.dialogData.content) {
      this.dialogRef.close(this.form.value);
    } else {
      this.dialogRef.close(true);
    }
  }

}
