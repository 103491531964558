import { NgModule } from '@angular/core';
import {DefaultFlexAlignDirective} from "./flex-align/flex-align";
import {DefaultLayoutDirective} from "./layout/layout";
import {DefaultLayoutGapDirective} from "./layout-gap/layout-gap";
import {DefaultLayoutAlignDirective} from "./layout-align/layout-align";
import {DefaultFlexDirective} from "./flex/flex";
import {FlexFillDirective} from "./flex-fill/flex-fill";
import {DefaultFlexOffsetDirective} from "./flex-offset/flex-offset";
import {DefaultFlexOrderDirective} from "./flex-order/flex-order";
import {CoreModule} from "../core";
import {BidiModule} from "@angular/cdk/bidi";

const ALL_DIRECTIVES = [
  DefaultLayoutDirective,
  DefaultLayoutGapDirective,
  DefaultLayoutAlignDirective,
  DefaultFlexOrderDirective,
  DefaultFlexOffsetDirective,
  FlexFillDirective,
  DefaultFlexAlignDirective,
  DefaultFlexDirective,
];

@NgModule({
  declarations: [...ALL_DIRECTIVES],
  imports: [CoreModule, BidiModule],
  exports: [...ALL_DIRECTIVES]
})
export class FlexModule { }
