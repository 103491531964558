import { NgModule } from '@angular/core';
import {DefaultGridAlignDirective} from "./grid-align/grid-align";
import {DefaultGridAlignRowsDirective} from "./align-rows/align-rows";
import {DefaultGridRowDirective} from "./row/row";
import {DefaultGridRowsDirective} from "./rows/rows";
import {DefaultGridAlignColumnsDirective} from "./align-columns/align-columns";
import {DefaultGridAreaDirective} from "./area/area";
import {DefaultGridGapDirective} from "./gap/gap";
import {DefaultGridAutoDirective} from "./auto/auto";
import {DefaultGridColumnsDirective} from "./columns/columns";
import {DefaultGridColumnDirective} from "./column/column";
import {DefaultGridAreasDirective} from "./areas/areas";
import {CoreModule} from "../core";

const ALL_DIRECTIVES = [
  DefaultGridAlignDirective,
  DefaultGridAlignColumnsDirective,
  DefaultGridAlignRowsDirective,
  DefaultGridAreaDirective,
  DefaultGridAreasDirective,
  DefaultGridAutoDirective,
  DefaultGridColumnDirective,
  DefaultGridColumnsDirective,
  DefaultGridGapDirective,
  DefaultGridRowDirective,
  DefaultGridRowsDirective,
];

@NgModule({
  imports: [CoreModule],
  declarations: [...ALL_DIRECTIVES],
  exports: [...ALL_DIRECTIVES]
})
export class GridModule { }
