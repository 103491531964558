<div mat-dialog-title>
  <p8-layout-settings-header [titlePath]="[dialogData.title]"
                             [iconRacine]="icon"></p8-layout-settings-header>
  <mat-card-subtitle *ngIf="dialogData.subtitle" style="padding-top: 10px;">{{dialogData.subtitle}}</mat-card-subtitle>
</div>

<mat-dialog-content>
  <div class="p8-cm_dialog-validation__body mat-typography">
    <span *ngIf="dialogData?.message" [innerHTML]="dialogData.message | safeHtml" style="float: left;width: 100%;"></span>
    <ng-container *ngIf="dialogData?.content" [formGroup]="form">
      <mat-form-field style="width: 100%;" *ngIf="dialogData?.contentElement === 'textarea'" appearance="{{dialogData?.contentFormOutline?'outline':''}}">
        <textarea formControlName="contentData" matInput placeholder="{{dialogData?.contentPlaceHolder}}" style="min-height: 120px;" required></textarea>
        <mat-hint align="end"><code>{{form?.get('contentData')?.value?.length}}</code></mat-hint>
      </mat-form-field>
      <mat-form-field style="width: 100%;" *ngIf="dialogData?.contentElement === 'input'" appearance="{{dialogData?.contentFormOutline?'outline':''}}">
        <input formControlName="contentData"
               [UpperCaseFirstLetter]="dialogData?.upperCaseFirstLetter"
               type="{{dialogData?.contentType}}"
               [maxlength]="max"
               matInput placeholder="{{dialogData?.contentPlaceHolder}}" required>
        <mat-hint align="end"><code>{{form.get('contentData').value?.length}}</code></mat-hint>
      </mat-form-field>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="{{dialogData.buttonJustify}}" style="float: right">
  <ng-container *ngIf="dialogData.action === dialogData.actionType.validation">
    <button *ngIf="dialogData.buttonTextFalse" mat-raised-button
            [color]="dialogData.buttonColorFalse"
            [matDialogClose]="false">
      {{ dialogData.buttonTextFalse }}
    </button>
    <button *ngIf="dialogData.buttonTextTrue" mat-raised-button
            [color]="dialogData.buttonColorTrue"
            [disabled]="isDisabled()"
            (click)="save()" >
      {{ dialogData.buttonTextTrue }}
    </button>
  </ng-container>

  <ng-container *ngIf="dialogData.action === dialogData.actionType.information">
    <button mat-raised-button
            [color]="dialogData.buttonColorTrue"
            [matDialogClose]="true">
      {{ 'Fermer' }}
    </button>
  </ng-container>

</mat-dialog-actions>

