<ng-container *ngIf="accessibility$ | async as accessibility">
  <mat-toolbar style="background: transparent;justify-content: end">
    <app-accessibility ></app-accessibility>
  </mat-toolbar>
</ng-container>
<form (ngSubmit)="login()" #loginForm1="ngForm"
      fxLayoutAlign="center" fxLayout="column"
      *ngIf="!modeAnonyme">
  <mat-card appearance="outlined" class="alert-warning" *ngIf="message">
    <mat-card-content fxLayout="row">
      {{message.message}}
      <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="panel-single">

    <div class="mat-subtitle-1 app-cm_login__title">Page d'authentification</div>

    <mat-card-content>
      <mat-form-field fxLayout="row" class="login-input" appearance="outline">
        <mat-icon matPrefix>person</mat-icon>
        <input matInput placeholder="Identifiant"
               name="username"
               required
               type="text"
               [(ngModel)]="model.username" data-cy="input-login-username">
      </mat-form-field>

      <mat-form-field fxLayout="row" class="login-input" appearance="outline">
        <mat-icon matPrefix>lock</mat-icon>
        <input matInput placeholder="Mot de passe" name="password" type="password" required
               [(ngModel)]="model.password" data-cy="input-login-password">

      </mat-form-field>
    </mat-card-content>
    <mat-card-actions fxLayout="column">
      <button class="login-button" mat-raised-button
              [disabled]="! canValidate()"
              type="submit"
              [color]="'primary'" data-cy="button-login-connection">Connexion
      </button>
    </mat-card-actions>
  </mat-card>
</form>
