<app-navbar *ngIf="loggedIn()"
            [opened]="opened" (open)="opened=$event" [titre]="titre" [displayLogon]="displayLogon" [menu]="menu"
            [homeApplication]="homeApplication" [avatar]="avatar"  [showAccessibility]="showAccessibility" [assetsSource]="assetsSource"
            [version]="version"
            [displaySidenav]="displaySidenav"
            [customNavBackground]="customNavBackground"
            [customNavFontColor]="customNavFontColor"></app-navbar>

<ng-container *ngIf="displaySidenav">
  <mat-sidenav-container [autosize]="autosize"
                         class="app-cm__home_sidenav-container"
                         [ngStyle]="{'background-color':bodyBgColor}"
                         fullscreen
                         (window:resize)="toggleSidenav($event)">
    <mat-sidenav *ngIf="loggedIn()"
                 #sidenav
                 class="app-cm__home_side-nav mat-elevation-z6 global__mat-sidenav-custom"
                 [mode]="sidenavMode"
                 [opened]="opened"
                 (openedChange)="toggleOpened($event)"
                 [ngClass]="{'global__mat-sidenav-custom': darkTheme}">

      <mat-list>
        <mat-list-item routerLink="/"
                       [routerLinkActive]="'app-cm__home_sidenav-active'"
                       [routerLinkActiveOptions]="{ exact: true }"
                       matTooltip="Accueil"
                       [matTooltipDisabled]="!miniSidenav"
                       matTooltipPosition="right"
                       matTooltipClass="global__tooltip"
                       matTooltipShowDelay="250">
          <mat-icon matListItemIcon>home</mat-icon>
          <a *ngIf="!miniSidenav"> Accueil </a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <ng-container *ngFor="let m of menu">
          <mat-list-item [routerLink]="m.url"
                         [routerLinkActive]="'app-cm__home_sidenav-active'"
                         [routerLinkActiveOptions]="{ exact: false }"
                         [matTooltip]="m.toolTip || m.titre"
                         [matTooltipDisabled]="disableTooltipElementMenu(m)"
                         matTooltipPosition="right"
                         matTooltipClass="global__tooltip"
                         matTooltipShowDelay="250">
            <app-menuicon matListItemIcon *ngIf="m.icon!==''" [icon]="m.icon"></app-menuicon>
            <a *ngIf="!miniSidenav"> {{ m.titre }} </a>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </mat-sidenav>

    <mat-sidenav-content class="content">
      <mat-card appearance="outlined" class="alert-warning" *ngFor="let message of messages; let i=index">
        <mat-card-content fxLayout="row">
          {{ message.message }}
          <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert(i)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="!displaySidenav">
  <div class="app-cm__home-container"
       [ngStyle]="{'background-color':bodyBgColor}"
       fullscreen>
    <div class="content">
      <mat-card appearance="outlined" class="alert-warning" *ngFor="let message of messages; let i=index">
        <mat-card-content fxLayout="row">
          {{ message.message }}
          <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert(i)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

      <router-outlet></router-outlet>
    </div>
  </div>

</ng-container>

