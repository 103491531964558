<div class="modal-contrast">
  <mat-card appearance="outlined">
    <mat-card-title>Paramètres d’accessibilité</mat-card-title>
  </mat-card>
  <mat-grid-list cols="9" rowHeight="100px">
    <mat-grid-tile class="modal-config" colspan="4">
      <mat-grid-tile-header>
        <h1 style="font-size: 20px">Contrastes.</h1>
      </mat-grid-tile-header>
      <mat-grid-tile-footer>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="selectedSettings"
          (change)="onClassChange(selectedSettings)">
          <mat-radio-button class="example-radio-button" *ngFor="let cls of contrastObjs" [value]="cls.value">
            {{cls.name}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-grid-tile-footer>
    </mat-grid-tile>

    <mat-grid-tile colspan="1"></mat-grid-tile>

    <mat-grid-tile class="modal-config" colspan="4">
      <mat-grid-tile-header>
        <h1 style="font-size: 20px">Police (dyslexie).</h1>
      </mat-grid-tile-header>
      <mat-grid-tile-footer>
        <mat-radio-group aria-label="Select an option"
                         [(ngModel)]="selectedFontFamily.fontClass"
                         (change)="onFontFamilyChange(selectedFontFamily)">
          <mat-radio-button [value]="fontsType.Default">Défaut</mat-radio-button>
          <mat-radio-button [value]="fontsType.Dys">Adapter</mat-radio-button>
        </mat-radio-group>

      </mat-grid-tile-footer>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="9" rowHeight="30px">
    <mat-grid-tile colspan="9"></mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="9" rowHeight="100px">
    <mat-grid-tile class="modal-config" colspan="4">
      <mat-grid-tile-header>
        <h1 style="font-size: 20px">Interlignage.</h1>
      </mat-grid-tile-header>
      <mat-grid-tile-footer>
        <mat-radio-group aria-label="Select an option"
                         [(ngModel)]="selectedInterlignage.interlignageClass"
                         (change)="onFontInterlignageChange(selectedInterlignage)">
          <mat-radio-button [value]="interlignageType.Default">Défaut</mat-radio-button>
          <mat-radio-button [value]="interlignageType.Dys">Augmenter</mat-radio-button>
        </mat-radio-group>
      </mat-grid-tile-footer>
    </mat-grid-tile>
  </mat-grid-list>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()" data-cy="btn-contrast-cancel">Annuler</button>
    <button mat-button (click)="saveConfigs()" data-cy="btn-contrast-save">Sauvegarder</button>
  </div>
</div>

