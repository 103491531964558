import { NgModule } from '@angular/core';
import {BROWSER_PROVIDER} from "./browser-provider";



@NgModule({
  declarations: [],
  exports: [],
  providers: [BROWSER_PROVIDER]
})
export class CoreModule { }
