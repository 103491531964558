import { NgModule } from '@angular/core';
import {DefaultClassDirective} from './class/class';
import {CoreModule} from "../core";
import {DefaultImgSrcDirective} from "./img-src/img-src";
import {DefaultStyleDirective} from "./style/style";
import {DefaultShowHideDirective} from "./show-hide/show-hide";

const ALL_DIRECTIVES = [
  DefaultClassDirective,
  DefaultImgSrcDirective,
  DefaultStyleDirective,
  DefaultShowHideDirective
];

@NgModule({
  imports: [CoreModule],
  declarations: [...ALL_DIRECTIVES],
  exports: [...ALL_DIRECTIVES]
})
export class ExtendedModule { }
