import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccueilComponent} from './accueil/accueil.component';
import {AuthGuardService} from "@Services/auth";
import {ForbiddenComponent} from "@Common/forbidden/forbidden.component";


const appRoutes: Routes = [
  {
    path: '',
    canLoad: [AuthGuardService],
    children: [
      {path: '', component: AccueilComponent},
      {path: 'candidatures', redirectTo: '/'}, // CandidatureVisualizeComponent.goBack fait un navigate vers candidatures
      {path: 'candidature', loadChildren: () => import('./candidature/candidature.module').then(m => m.CandidatureModule)},
      {path: 'historique', loadChildren: () => import('./historique/historique.module').then(m => m.HistoriqueModule)},
    ]
  },
  {path: 'forbidden', component: ForbiddenComponent},
  {path: '**', component: AccueilComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {})
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule {
}
