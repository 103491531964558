import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "@Common/core/login/login.component";
import {DefaultLoginComponent} from "@Common/core/login/default-login/default-login.component";
import {ExternalLoginComponent} from "@Common/core/login/external-login/external-login.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@Common/widgets/flex-layout/flex-layout.module";
import {MatAngularModule} from "@Common/mat-angular.module";
import {LayoutModule} from "@Common/widgets/general/layout/layout.module";
import {AccessibilityModule} from "@Common/widgets/accessibility/accessibility.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAngularModule,
    LayoutModule,
    AccessibilityModule
  ],
  declarations: [
    LoginComponent,
    DefaultLoginComponent,
    ExternalLoginComponent
  ],
  exports: [
    LoginComponent,
    DefaultLoginComponent,
    ExternalLoginComponent
  ],
  providers: []
})
export class LoginModule { }
