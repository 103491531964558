<div style="border: 1px solid #dddddd; padding: 10px;margin: 10px;">
    <data-table-header-component *ngIf="component && position"
                                 [component]="component"
                                 [position]="position"
                                 (onClick)="onClick($event)"
                                 remove-host-view-container>
    </data-table-header-component>

</div>

