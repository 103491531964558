import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CandidatureVisualizeComponent} from '@Common/apps/ater/components/candidature-visualize/candidature-visualize.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@Common/widgets/flex-layout/flex-layout.module';
import {MatAngularModule} from '@Common/mat-angular.module';
import {CommonModuleP8} from '@Common/common-p8.module';
import {
  CandidaturePjsValidationComponent
} from '@Common/apps/ater/components/candidature-pjs-validation/candidature-pjs-validation.component';
import {ImagepdfviewerModule} from '@Common/widgets/general/imagepdfviewer';
import {QuillModule} from 'ngx-quill';
import {CandidatureStatutComponent} from '@Common/apps/ater/components/candidature-statut/candidature-statut.component';
import {CandidatureStatusComponent} from '@Common/apps/ater/components/candidature-status/candidature-status.component';

import {DialogImagesComponent} from '@Common/apps/ater/components/common/dialog-images/dialog-images.component';
import {ImagePreviewComponent} from '@Common/apps/ater/components/common/image-preview/image-preview.component';
import {CampagneFilterComponent} from '@Common/apps/ater/components/campagne-filter/campagne-filter.component';
import {OffreVisualizeComponent} from '@Common/apps/ater/components/offre-visualize/offre-visualize.component';
import {CandidatureMotifComponent} from "@Common/apps/ater/components/candidature-motif/candidature-motif.component";
import {NeoModule} from '@Common/neo-components/neo.module';

@NgModule({
  declarations: [
    CandidatureVisualizeComponent,
    CandidaturePjsValidationComponent,
    CandidatureStatutComponent,
    CandidatureStatusComponent,
    OffreVisualizeComponent,
    DialogImagesComponent,
    ImagePreviewComponent,
    CampagneFilterComponent,
    CandidatureMotifComponent
  ],
  exports: [
    CandidatureVisualizeComponent,
    CandidaturePjsValidationComponent,
    CandidatureStatutComponent,
    CandidatureStatusComponent,
    OffreVisualizeComponent,
    DialogImagesComponent,
    ImagePreviewComponent,
    CampagneFilterComponent,
    CandidatureMotifComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatAngularModule,
        CommonModuleP8,
        QuillModule.forRoot(),
        ImagepdfviewerModule,
        NeoModule,
    ]
})
export class AterCommonModule {
}
