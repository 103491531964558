import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@Common/widgets/flex-layout/flex-layout.module";
import {MatAngularModule} from "@Common/mat-angular.module";
import {SettingsLayoutFooterComponent} from "@Common/widgets/general/layout/settings-footer/settings-footer.component";
import {SettingsLayoutHeaderComponent} from "@Common/widgets/general/layout/settings-header/settings-header.component";
import {SettingsLayoutPageComponent} from "@Common/widgets/general/layout/settings-page/settings-page.component";
import {SettingsLogoTitleComponent} from '@Common/widgets/general/layout/settings-logo-title/settings-logo-title.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAngularModule,
    NgOptimizedImage,
  ],
  declarations: [
    SettingsLayoutFooterComponent,
    SettingsLayoutHeaderComponent,
    SettingsLayoutPageComponent,
    SettingsLogoTitleComponent
  ],
  exports: [
    SettingsLayoutFooterComponent,
    SettingsLayoutHeaderComponent,
    SettingsLayoutPageComponent,
    SettingsLogoTitleComponent
  ],
  providers: []
})
export class LayoutModule { }
