<ng-container *ngIf="accessibility$ | async as accessibility">
  <mat-toolbar style="background: transparent;justify-content: end">
    <app-accessibility ></app-accessibility>
  </mat-toolbar>
</ng-container>
<form (ngSubmit)="login()" #loginForm1="ngForm"
      fxLayoutAlign="center" fxLayout="column"
      *ngIf="!modeAnonyme">
  <mat-card appearance="outlined" class="alert-warning" *ngIf="message">
    <mat-card-content fxLayout="row">
      {{message.message}}
      <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert()" data-cy="btn-external-login-close">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" class="panel-single">
    <div *ngIf="applicationName" class="mat-subtitle-1 app-cm_login__title">{{ applicationName }}</div>
    <div class="mat-subtitle-1 app-cm_login__title">Connectez-vous</div>

    <mat-card-content>
      <mat-form-field fxLayout="row" appearance="outline" class="login-input">
        <mat-icon matPrefix>person</mat-icon>
        <input matInput placeholder="Identifiant"
               name="username"
               required
               type="text"
               [(ngModel)]="model.username" data-cy="input-external-login-username">
      </mat-form-field>

      <mat-form-field fxLayout="row" appearance="outline" class="login-input">
        <mat-icon matPrefix>lock</mat-icon>
        <input matInput placeholder="Mot de passe" name="password" type="password" required
               [(ngModel)]="model.password" data-cy="input-external-login-pwd">
      </mat-form-field>
      <a class="forgotten-pass" href="javascript: void(0);" (click)="resetPwd()" fxLayoutAlign="end"> Mot de passe oublié</a>
    </mat-card-content>
    <mat-card-actions fxLayout="column">
      <div class="mode-op-dl" *ngIf="application === accessToApplication.ater_candidature">
        <a href="javascript: void(0);" (click)="downloadFile()">
          Télécharger le mode opératoire
        </a>&nbsp;&nbsp;
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"
           matTooltip="Télécharger le mode opératoire pour l'initialisation du mot de passe."></i>
      </div>

      <button class="login-button"
              mat-raised-button
              [disabled]="! canValidate()"
              type="submit"
              [color]="'primary'" data-cy="btn-external-login-connection">Connexion
      </button>
    </mat-card-actions>
  </mat-card>
</form>
